@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#010101] font-raleway transition-all

  
}

